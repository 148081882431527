<template>
  <div class="realtor">
    <a-table
      :loading="tableLoading"
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      :scroll="{ x: 2200, y: 500 }"
      @change="changeTable"
      :customRow="changeTableRow"
      :rowClassName="rowClassName"
      :rowKey="(record) => record.id"
    >
      <span slot="confirmStatus" slot-scope="text">
        <a-tag v-if="text == 0" color="#909399">未确认</a-tag>
        <a-tag v-if="text == 1" color="#67c23a">已确认</a-tag>
      </span>
      <span slot="orderStatus" slot-scope="text">
        <a-tag v-if="text == 0" color="#e6a23c">待审核</a-tag>
        <a-tag v-if="text == 1" color="#909399">已关闭</a-tag>
        <a-tag v-if="text == 2" color="#909399">已取消</a-tag>
        <a-tag v-if="text == 3" color="#ff7875">审核拒绝</a-tag>
        <a-tag v-if="text == 4 && source == 'dealer'" color="#67c23a">已审核待发货</a-tag>
        <a-tag v-if="text == 4 && source == 'linkgap'" color="#67c23a">已审核</a-tag>
        <a-tag v-if="text == 5" color="#108ee9">部分发货</a-tag>
        <a-tag v-if="text == 6" color="#67c23a">已发货（全部发货）</a-tag>
        <a-tag v-if="text == 7" color="#67c23a">已完成（用户签收）</a-tag>
        <a-tag v-if="text == 8" color="#333333">已评价</a-tag>
        <a-tag v-if="text == 9" color="#333333">草稿</a-tag>
      </span>

      <span slot="linkGapContractNum" slot-scope="text, row">
        <span @click.stop="">
          <a @click="checkPDF(row.linkGapContractUrl,'销售合同')">{{ text }}</a>
        </span>
      </span>
      <span slot="stContractNum" slot-scope="text, row">
        <span @click.stop="">
          <a @click="checkPDF(row.stContractUrl,'采购合同')">{{ text }}</a>
        </span>
      </span>
      <span slot="sourceType" slot-scope="text">
        <span v-if="text == 1">代客要货</span>
        <span v-if="text == 2">自主要货</span>
      </span>
      <span slot="consigneeType" slot-scope="text, row">
        <a-tag v-if="text == 1" color="#67c23a">个人</a-tag>
        <a-tag v-if="text == 2" color="#108ee9">公司</a-tag>
        <span>{{ row.companyName }}</span>
      </span>
      <span slot="deliveryProperties" slot-scope="text">
        <a-tag v-if="text == 1">正常发货</a-tag>
        <a-tag v-if="text == 2">赠送</a-tag>
        <a-tag v-if="text == 3">售后</a-tag>
      </span>
      <span slot="alreadySendGoodsCount" slot-scope="text, record">
        <a-button style="width: 80px" icon="eye" @click.stop="getNoShipProList(record)">{{
          record.goodsCount - record.alreadySendGoodsCount
        }}</a-button>
      </span>
      <span slot="flagFreeShipping" slot-scope="text">
        <span v-if="text"><a-icon type="check-circle" style="color: green" theme="filled" /></span>
        <span v-else><a-icon type="close-circle" style="color: red" theme="filled" /> </span>
      </span>
      <span slot="purchaseAndSalesContractType" slot-scope="text">
        <a-tag v-if="text == 1" color="#67c23a">个人</a-tag>
        <a-tag v-if="text == 2" color="#108ee9">公司</a-tag>
      </span>

      <span slot="bucklePoint" slot-scope="text">
        <span> {{ (text || 0).toFixed(2) }} </span>
      </span>
      <span slot="supplierName" slot-scope="text, row">
        <span> {{ row.confirmDiscount ? row.supplierName : '未确认' }} </span>
        <span v-if="row.confirmDiscount">
          ({{ row.supplierDiscount ? row.supplierDiscount.toFixed(3) : row.supplierDiscount }})
        </span>
      </span>
      <span slot="payAmount" slot-scope="text">
        <span v-if="!text">--</span>
        <span v-else>{{ text | formatMoney }}</span>
      </span>
      <span slot="bigAreaName" slot-scope="text, row"> 【{{ row.bigAreaName }}】{{ row.firmName }} </span>
      <span slot="address" slot-scope="text, row">
        {{ row.customerName }}，{{ row.customerContact }}
        <br />
        {{ row.provinceName }}{{ row.cityName }}{{ row.areaName }}{{ row.address }}
      </span>
      <span slot="fhNum" slot-scope="text, record">
        <a-progress :percent="(record.alreadySendGoodsCount / record.goodsCount) * 100" :status="null" />
      </span>
      <span slot="policyTotalAmount" slot-scope="text">
        {{ (text || 0.0) | formatMoney }}
      </span>
      <div slot="orderCode" slot-scope="text, row">
        <div v-for="(item, index) in row.orderCode" :key="index">
          <a @click="onCheckOrder(item)"> {{ item }} </a>
          <a-icon type="copy" @click.stop="copy(item)" />
        </div>
      </div>
      <span slot="rebateTotalAmount" slot-scope="text">
        {{ (text || 0.0) | formatMoney }}
      </span>
      <span slot="dealerName" slot-scope="text,row"><a @click="onCheckDealerInfo(row)">{{text}}</a> </span>

    </a-table>
    <!-- 抽屉PDF -->
    <a-drawer
      :title="title"
      width="50%"
      height="100%"
      placement="right"
      :closable="false"
      :visible="visiblePDF"
      @close="(close) => (this.visiblePDF = false)"
    >
      <pdf class="pdfView" v-for="item in pageNum" :key="item" :src="PDF" :page="item"></pdf>
    </a-drawer>
    <!-- 查看订单 -->
    <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>

    <!--未发货商品-->
    <NoShipProductList ref="NoShipProductList"></NoShipProductList>
    <CustomerInfoCheckModal ref="CustomerInfoCheckModal" />
  </div>
</template>

<script>
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import NoShipProductList from '@/views/order_info/components/NoShipProductList.vue'
import pdf from 'vue-pdf'

const qs = require('qs')

export default {
  name: 'orderListAll',
  components: {
    OrderInfoCheckModal,
    NoShipProductList,
    pdf,
    CustomerInfoCheckModal: () => import('../customer/components/CustomerInfoCheckModal.vue')
  },
  data() {
    return {
      PDF: '',
      title: '销售合同',
      pageNum: 10,
      visiblePDF: false,
      tableData: [],
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      tableColumns: [
        {
          title: '订单编号',
          dataIndex: 'orderCode',
          key: 'orderCode',
          width: 250,
          align: 'center',
          ellipsis: true,
          fixed: 'left',
          scopedSlots: { customRender: 'orderCode' },
        },
        {
          title: '大区/事务所',
          dataIndex: 'bigAreaName',
          key: 'bigAreaName',
          width: 250,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'bigAreaName' },
        },
        {
          title: '客户名称',
          dataIndex: 'dealerName',
          key: 'dealerName',
          width: 250,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'dealerName' },

        },
        {
          title: '经销商名称',
          dataIndex: 'orderBelongsName',
          key: 'orderBelongsName',
          width: 150,
          align: 'center',
          ellipsis: true,
        },
        {
          title: '销售合同号',
          dataIndex: 'linkGapContractNum',
          key: 'linkGapContractNum',
          width: 200,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'linkGapContractNum' },
        },
        {
          title: '采购合同号',
          dataIndex: 'stContractNum',
          key: 'stContractNum',
          width: 200,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'stContractNum' },
        },
        {
          title: '数量',
          dataIndex: 'goodsCount',
          key: 'goodsCount',
          width: 100,
          align: 'center',
        },
        {
          title: '订单金额(元)',
          dataIndex: 'payAmount',
          key: 'payAmount',
          width: 150,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'payAmount' },
        },
        {
          title: '政策优惠金额(元)',
          dataIndex: 'policyTotalAmount',
          key: 'policyTotalAmount',
          align: 'center',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'policyTotalAmount' },
        },
        {
          title: '折扣优惠金额(元)',
          dataIndex: 'rebateTotalAmount',
          key: 'rebateTotalAmount',
          align: 'center',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'rebateTotalAmount' },
        },
        {
          title: '供应商（折扣率）',
          dataIndex: 'supplierName',
          key: 'supplierName',
          width: 200,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'supplierName' },
        },
        {
          title: '扣点',
          dataIndex: 'bucklePoint',
          key: 'bucklePoint',
          width: 100,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'bucklePoint' },
        },
        {
          title: '未发数量',
          dataIndex: 'alreadySendGoodsCount',
          key: 'alreadySendGoodsCount',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'alreadySendGoodsCount' },
        },
        {
          title: '下单时间',
          dataIndex: 'orderDate',
          key: 'orderDate',
          width: 200,
          align: 'center',
          ellipsis: true,
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          key: 'orderStatus',
          width: 140,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'orderStatus' },
        },

        {
          title: '业绩归属经销商',
          dataIndex: 'belongDealerName',
          key: 'belongDealerName',
          width: 150,
          align: 'center',
        },
        {
          title: '购货单位',
          dataIndex: 'consigneeType',
          key: 'consigneeType',
          width: 300,
          align: 'left',
          scopedSlots: { customRender: 'consigneeType' },
          ellipsis: true,
        },
        // {
        //   title: '是否包邮',
        //   dataIndex: 'flagFreeShipping',
        //   key: 'flagFreeShipping',
        //   width:150,
        //   align:'center',
        //   scopedSlots: { customRender: 'flagFreeShipping' },
        //   // ellipsis:true
        // },
        {
          title: '发货性质',
          dataIndex: 'deliveryProperties',
          key: 'deliveryProperties',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'deliveryProperties' },
          ellipsis: true,
        },
        // {
        //   title: '购销合同类型',
        //   dataIndex: 'purchaseAndSalesContractType',
        //   key: 'purchaseAndSalesContractType',
        //   width:150,
        //   align:'center',
        //   scopedSlots: { customRender: 'purchaseAndSalesContractType' },
        //   // ellipsis:true
        // },
        // {
        //   title: '合同类型',
        //   dataIndex: 'contractType',
        //   key: 'contractType',
        //   width:150,
        //   align: 'center',
        //   // ellipsis: true,
        // },
        {
          title: '订单类型',
          dataIndex: 'orderType',
          key: 'orderType',
          width: 150,
          align: 'center',
          ellipsis: true,
        },
        {
          title: '下单方式',
          dataIndex: 'sourceType',
          key: 'sourceType',
          width: 150,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'sourceType' },
        },
        // {
        //   title: '政策优惠金额(元)',
        //   dataIndex: 'policyTotalAmount',
        //   key: 'policyTotalAmount',
        //   align: 'center',
        //   width: 150,
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'policyTotalAmount' },
        // },

        // {
        //   title: '订单金额(元)',
        //   dataIndex: 'payAmount',
        //   key: 'payAmount',
        //   width: 150,
        //   align: 'center',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'payAmount' },
        // },

        {
          title: '已发/总数',
          dataIndex: 'fhNum',
          key: 'fhNum',
          width: 150,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'fhNum' },
        },
        // {
        //   title: '期望到货时间',
        //   dataIndex: 'expectTime',
        //   key: 'expectTime',
        //   width:150,
        //   align:'center'
        // },
        // {
        //   title: '下单时间',
        //   dataIndex: 'orderDate',
        //   key: 'orderDate',
        //   width: 200,
        //   align: 'center',
        //   ellipsis: true,
        // },
        {
          title: '收货人信息',
          dataIndex: 'address',
          key: 'address',
          width: 300,
          align: 'left',
          scopedSlots: { customRender: 'address' },
        },
        // {
        //   title: '供应商（折扣率）',
        //   dataIndex: 'supplierDiscount',
        //   key: 'supplierDiscount',
        //   width: 200,
        //   align: 'left',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'supplierDiscount' },
        // }
      ],
      columns: [],
    }
  },

  computed: {
    rowSelection() {
      const _this = this
      return {
        // fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
          _this.$emit('orderData', _this.selectedRows)
          _this.$emit('orderDataKey', _this.selectedRowKeys)
        },
      }
    },
  },

  props: {
    url: {
      type: String,
      default: '',
    },
    columnsData: {
      type: Array,
      default: [],
    },
    status: {
      type: Array,
      default: [],
    },
    isToDelivery: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: 'linkgap',
    },
    text: {
      type: String,
      default: '',
    },
  },
  methods: {

    onCheckDealerInfo(row){
      const _this = this;
      const item = {
        id:row.dealerId
      }
      _this.$refs.CustomerInfoCheckModal.setRowData(item, 'check')
    },

    // 获取PDF页码
    getPageNum () {
      const loadingTask = pdf.createLoadingTask(this.PDF,{withCredentials: false})
      loadingTask.promise.then(pdf => {
        this.pageNum = pdf.numPages
      }).catch(err => {
        console.error('pdf加载失败', err);
      })
    },

    // 查看PDF
    checkPDF(data,title) {
      this.axios.get('/api/order/order/orderInfo/checkContractExist?path='+data).then((res) => {
        if (res.code == 200) {
          if(res.body == 200){
            this.title = title
            this.PDF = '/api/order/order/orderInfo/showPdf?fileName='+data
            console.log(this.PDF)
            console.log(JSON.parse(JSON.stringify(data)), '查看PDF')

            this.getPageNum();
            this.visiblePDF = true
          }else{
            this.$notification.error({ message: '文件不存在' })
          }
        }
      })
    },
    // 复制
    copy(item) {
      let data = item
      let oInput = document.createElement('input')
      oInput.value = data
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy')
      this.$message.success('复制成功！')
      oInput.remove()
    },
    getNoShipProList(row) {
      this.$refs.NoShipProductList.isShow(row)
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    /**
     * 获取表格数据
     */
    getData(res, pagination) {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []

      let obj = {
        // pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        statusList: this.status,
        isToDelivery: this.isToDelivery,
      }
      if (pagination) {
        obj.pageNumber = pagination
      } else {
        obj.pageNumber = this.page.current
      }
      const postData = Object.assign(obj, res)
      this.axios
        .get(`${this.url}?${qs.stringify(postData, { arrayFormat: 'repeat' })}`)
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            let list = []
            for (let i = 0; i < records.length; i++) {
              if (!records[i].orderCode) {
                records[i].orderCode = i
              } else {
                let code = records[i].orderCode.split('，')
                for (let x = 0; x < code.length; x++) {
                  list.push(code[x])
                }
              }
              records[i].orderCode = list
              list = []
            }
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },

    // Bus总线调用getData方法
    getList() {
      this.$bus.$on('getList', (res, pagination) => {
        this.getData(res, 1)
      })
    },
    getchangeTable() {
      this.$bus.$on('getchangeTable', (res) => {
        this.getData(res)
      })
    },
    onCheckOrder(row) {
      this.$refs.OrderInfoCheckModal.isShow(row, 'fhd')
    },

    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.$bus.$on('reset', (res) => {
        this.page.current = 1
        this.page.pageSize = 10
        this.getData(res)
      })
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.$emit('changeTable')
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
            this.$emit('orderData', this.selectedRows)
          },
        },
      }
    },
  },
  created() {
    this.$bus.$off(['getList', 'getchangeTable', 'reset'])
    // this.getData()
    this.getchangeTable()
    this.getList()
    this.reset()
    this.tableColumns.forEach((e) => {
      this.columnsData.forEach((i) => {
        if (e.dataIndex == i) {
          this.columns.push(e)
        }
      })
    })
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
.footer-bts {
  text-align: right;
}
///deep/ .ant-table-tbody tr{
//    height: 62.6px !important;
//  }
</style>