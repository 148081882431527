<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-23 18:14:47
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-07 17:34:21
-->
<template>
  <div class="invoice_review">
    <a-modal
      title="审核"
      width="35%"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :footer="null"
      @cancel="visible = false"
    >
      <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 10 }">
        <a-form-model-item label="是否通过">
          <a-radio-group v-model="audit_status" @change="onStatusChange">
            <a-radio :value="4"> 通过</a-radio>
            <a-radio :value="3"> 拒绝</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="rowData.rejectReason"></a-textarea>
        </a-form-model-item>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="visible = false">取消</a-button>
        <a-button type="danger" :loading="loading" v-if="statusRadio == 3" @click="onAudit('refuse')">确定</a-button>
        <a-button type="primary" :loading="loading" v-if="statusRadio == 4" @click="onAudit('audit')">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {auditTask} from "@/api/activiti";

export default {
  name: 'invoice_review',

  data() {
    return {
      confirmLoading: false,
      visible: false,
      statusRadio: 4,
      audit_status: 4,
      rowData: {},
      supplierList: [],
      warehouseList: [],
      supplier: '请选择',
      warehouse: '请选择',
      changeRadio: 1,
      loading:false,
      obj: {},
      taskId: ''
    }
  },

  computed: {},

  created() {
  },

  methods: {
    show(row) {
      this.visible = true
      this.taskId = row.taskId
      this.obj = row
      this.rowData = {}
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '供应商').then((res) => {
        // 获取字典供应商
        this.supplierList = res.body
      })
    },

    onStatusChange(val) {
      this.statusRadio = val.target.value
    },

    // 审核,改为审批流接口
    // onAudit(name) {
    //   let query = {
    //     ids: this.obj,
    //     authStatus: name == 'audit' ? 2 : 3,
    //     verifyRemark: this.rowData.rejectReason,
    //   }
    //   this.axios.post('/api/order/system/orderShipInfo/approveShipmentDocList', query).then((res) => {
    //     if (res.code == 200) {
    //       this.$message.success(res.message)
    //       this.rowData = {}
    //       this.$emit('reload')
    //       this.visible = false
    //     } else {
    //       this.$message.error(res.message)
    //       this.$emit('reload')
    //       this.visible = false
    //     }
    //   })
    // },

    // 审核
    onAudit(name) {
      const _that = this
      const query = {
        taskId: _that.taskId,
        result: name == 'audit' ? 2 : 3,
        comment: _that.rowData.rejectReason != null ? _that.rowData.rejectReason : ''
      }
      _that.loading = true
      auditTask(query).then((res) => {
        if (res.code == 200) {
          _that.$message.success(res.message)
          _that.rowData = {}
          _that.$emit('reload')
          _that.visible = false
          _that.loading = false
        } else {
          _that.$message.error(res.message)
          _that.$emit('reload')
          _that.loading = false
        }
      }).catch(e =>{
        _that.loading = false
      })
    },

  },
}
</script>

<style lang='scss' scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>