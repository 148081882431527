<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-28 21:53:44
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-10 16:47:43
-->
<template>
    <a-button :type="type" :loading="isConfirmLoading"  @click="downLoad" icon="download">
        <slot>导出</slot>
    </a-button>
</template>

<script>
    import qs from 'qs'
    import { ACCESS_TOKEN } from '@/store/mutation-types'
    export default {
        data() {
            return {

            }
        },
        props: {
            api: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
           params: {
                type: Object
            },
            method:{
                type:String,
                default:'post'
            },
            isConfirmLoading:{
                type:Boolean,
                default:false
            },
            type: {
                type: String,
                default:''
            }
        },
        methods: {

            downLoad() {
              this.$emit('startDownLoad')
                function createObjectURL(object) {
                    return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(
                        object);
                }
                var xhr = new XMLHttpRequest();
                let formData = new FormData();
                let api=this.api
                if(this.method=='post'){
                    let tmpArr = Object.keys(this.params)
                    tmpArr.forEach(x => {
                        formData.append(x, this.params[x])
                    })
                }else{
                    api=`${this.api}?${qs.stringify(this.params, { arrayFormat: 'repeat' })}`
                }

                // xhr.open('post', `${process.env.API_ROOT}/engineering/manage/projectPriceApply/projectItemExport`);
                xhr.open(`${this.method}`, `${api}`);
                xhr.setRequestHeader("Authorization-Admin", this.$store.getters.token);
                xhr.responseType = 'blob';
                var that=this
                xhr.onload = function (e){
                    if (this.status == 200) {
                        that.$emit('downLoadDone')
                        var blob = this.response;
                        var filename = that.name;
                        if (window.navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(blob, filename);
                        } else {
                            var a = document.createElement('a');
                            var url = createObjectURL(blob);
                            a.href = url;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                        }


                    }
                };
                xhr.send(formData);
            }
        },
        created() {

        }
    }
</script>

<style lang="scss" scoped>
</style>
