<template>
  <a-modal
    :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
  >
    <a-tabs type="card" @change="callback">
      <a-tab-pane key="1" tab="基础信息">
        <a-form-model
          ref="form"
          :model="rowData"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 21 }"
        >
          <a-form-model-item label="大区及事务所" prop="">
            【{{rowData.area}}】{{rowData.firmName}}
          </a-form-model-item>
          <a-form-model-item label="上级经销商" v-if="rowData.parentName" prop="">
            <a-input :disabled="handle == 'check'" v-model="rowData.parentName" placeholder="请输入经销商全称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="经销商全称" prop="name">
            <a-input :disabled="handle == 'check'" v-model="rowData.name" placeholder="请输入经销商全称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="经销商编码" prop="dealerCode" v-if="Classify != 2">
            <a-input :disabled="handle == 'check'" v-model="rowData.dealerCode" placeholder="请输入经销商编码"></a-input>
          </a-form-model-item>
          <a-form-model-item label="门店地址" prop="receiptInvoice">
            <PCD
              ref="pcd"
              :disabled="handle == 'check'"
              placeholder="请选择区域"
              :province.sync="rowData.province"
              :city.sync="rowData.city"
              :district.sync="rowData.county"
              :provinceId.sync="rowData.provinceId"
              :cityId.sync="rowData.cityId"
              :districtId.sync="rowData.countyId"
            >
            </PCD>
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="address">
            <a-textarea :disabled="handle == 'check'" :max-length="50" v-model="rowData.address" placeholder="请输入详细地址"></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="所属大区及事务所" prop="">
            <a-cascader
              :options="options"
              @change="onChange"
              placeholder="请选择"
              v-model="RegOff"
              :disabled="handle == 'check'"
              :field-names="{ label: 'name', value: 'id', children: 'firmVOS' }"
            />
          </a-form-model-item>
          <a-form-model-item label="经销商类型" prop="dealerType">
            <a-radio-group :disabled="handle == 'check'" :options="dealerOptions" v-model="rowData.dealerType" />
          </a-form-model-item>
          <!-- <a-form-model-item label="合作状态" prop="cooperateStatus">
            <a-radio-group
              :disabled="handle == 'check'"
              :options="consociationOptions"
              v-model="rowData.cooperateStatus"
            />
          </a-form-model-item> -->
          <a-form-model-item label="是否物优家店" prop="flagLinkgap">
            <a-switch
              :disabled="handle == 'check'"
              v-model="rowData.flagLinkgap"
              checked-children="是"
              un-checked-children="否"
              default-checked
            />
          </a-form-model-item>
          <!-- <a-form-model-item label="是否合作伙伴" prop="flagLinkgap">
            <a-switch
              :disabled="handle == 'check'"
              v-model="rowData.cooperativePartner"
              checked-children="是"
              un-checked-children="否"
              default-checked
            />
          </a-form-model-item> -->
          <a-form-model-item label="权限组" prop="templateId">
            <a-select placeholder="请选择权限组" v-model="rowData.templateId" :disabled="handle == 'check'">
              <a-select-option :value="item.id" v-for="(item, index) in templateList" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="账号" prop="account">
            <a-input :disabled="handle == 'check'" v-model="rowData.account" placeholder="请输入账号"></a-input>
          </a-form-model-item>
          <a-form-model-item label="邮箱" prop="email">
            <a-input :disabled="handle == 'check'" v-model="rowData.email" placeholder="请输入邮箱"></a-input>
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remark" :labelCol="{ span: 3 }" :wrapperCol="{ span: 21 }">
            <a-textarea :disabled="handle == 'check'" v-model="rowData.remark" placeholder="请输入"></a-textarea>
          </a-form-model-item>
        </a-form-model>
        <div class="footer-bts" v-if="handle != 'check'">
          <a-button key="back" @click="handleCancel">取消</a-button>
          <a-button key="submit" type="primary" :loading="loading" @click="toSubmit">提交</a-button>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="联系人信息">
        <a-card :v-if="handle == 'check'" :key="index" v-for="(item, index) in contactsList">
          <a-row>
            <a-descriptions :column="3" size="small" v-if="item.flagDefault">
              <a-descriptions-item label="">
                <a-tag color="#f50">默认</a-tag>
              </a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small">
              <a-descriptions-item label="联系人">{{ item.contacts }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small">
              <a-descriptions-item label="联系电话"> {{ item.phone }}</a-descriptions-item>
            </a-descriptions>
          </a-row>
          <!-- <div class="footer-bts">
            <a @click="setDefault(item.id, 1)">设为默认</a>
            <a-divider type="vertical" />
            <a @click="setDelete(item.id, 1)">删除</a>
          </div> -->
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="3" tab="收货地址" v-if="Classify != 2">
        <a-card :v-if="handle == 'check'" :key="index" v-for="(item, index) in deliveryAddressesList">
          <a-row>
            <a-descriptions :column="3" size="small" v-if="item.flagDefault">
              <a-descriptions-item label="">
                <a-tag color="#f50">默认</a-tag>
              </a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small">
              <a-descriptions-item label="收货人">{{ item.contacts }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small">
              <a-descriptions-item label="联系电话"> {{ item.phone }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small">
              <a-descriptions-item label="所属区域"
                >{{ item.province }}/ {{ item.city }}/ {{ item.area }}</a-descriptions-item
              >
            </a-descriptions>
            <a-descriptions :column="3" size="small">
              <a-descriptions-item label="详细地址"> {{ item.address }}</a-descriptions-item>
            </a-descriptions>
          </a-row>
          <!-- <div class="footer-bts">
            <a @click="setDefault(item.id, 2)">设为默认</a>
            <a-divider type="vertical" />
            <a @click="setDelete(item.id, 2)">删除</a>
          </div> -->
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="4" tab="发票信息" v-if="Classify != 2">
        <a-card :v-if="handle == 'check'" :key="index" v-for="(item, index) in invoiceList">
          <a-row>
            <a-descriptions :column="3" size="small" v-if="item.flagDefault">
              <a-descriptions-item label="">
                <a-tag color="#f50">默认</a-tag>
              </a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small">
              <a-descriptions-item label="抬头类型">{{
                item.receivingType == 1 ? '个人' : '公司'
              }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small">
              <a-descriptions-item :label="item.receivingType == 1 ? '抬头名称' : '公司名称'">
                {{ item.invoiceTitle }}</a-descriptions-item
              >
            </a-descriptions>
            <a-descriptions :column="3" size="small" v-if="item.receivingType == 2">
              <a-descriptions-item label="纳税人识别码"> {{ item.taxpayerNumber }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small" v-if="item.receivingType == 2">
              <a-descriptions-item label="注册电话"> {{ item.regPhone }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small" v-if="item.receivingType == 2">
              <a-descriptions-item label="注册地址"> {{ item.regAddress }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small" v-if="item.receivingType == 2">
              <a-descriptions-item label="开户银行"> {{ item.depositBank }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small" v-if="item.receivingType == 2">
              <a-descriptions-item label="银行账号"> {{ item.bankAccount }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions :column="3" size="small" v-if="item.receivingType == 2">
              <a-descriptions-item label="开票类型"> {{item.invoiceType == 1 ? '小规模' : '一般纳税人'}}</a-descriptions-item>
            </a-descriptions>
          </a-row>
          <!-- <div class="footer-bts">
            <a @click="setDefault(item.id, 3)">设为默认</a>
            <a-divider type="vertical" />
            <a @click="setDelete(item.id, 3)">删除</a>
          </div> -->
        </a-card>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>
<script>
import { editCustomerInfo, selectByIdCustomerInfo, addCustomerInfo } from '../api/CustomerInfoApi'
export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      loading: false,
      options: [],
      Classify:'',
      dealerOptions: [
        {
          value: 1,
          label: '中心店',
        },
        {
          value: 2,
          label: '核心店',
        },
        {
          value: 3,
          label: '一般店',
        },
        {
          value: 4,
          label: '核心店（预备）',
        },
      ],
      consociationOptions: [
        {
          value: 1,
          label: '合作中',
        },
        {
          value: 2,
          label: '合作终止',
        },
      ],
      RegOff: [],
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入经销商全称', trigger: 'blur' }],
        dealerCode: [{ required: true, message: '请输入经销商编码', trigger: 'blur' }],
        // phone: [
        //   { required: true, pattern: new RegExp(/^1[3456789]\d{9}$/ || /^[0][1-9]{2,3}-[0-9]{5,10}$/), message: '请输入正确的手机号', trigger: 'blur' },
        // ],
        templateId: [{ required: true, message: '请选择权限组', trigger: 'change' }],
        account: [{ required: true, message: '请输入帐号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入初始密码', trigger: 'blur' }],
        email: [{pattern: new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/), message: '请输入正确的邮箱地址', trigger: 'blur'}],
        // taxCode: [
        //   { required: true, pattern: new RegExp(/^[0-9]{1,18}$/ || /^[0][1-9]{2,3}-[0-9]{5,10}$/), message: '请输入纳税识别号', trigger: 'blur' },
        // ],
      },
      templateList: [],
      contactsList: [
        {
          contacts: '',
          phone: '',
          flagDefault: false,
        },
      ],
      deliveryAddressesList: [
        {
          province: '',
          provinceId: 0,
          city: '',
          cityId: 0,
          area: '',
          areaId: 0,
          flagDefault: false,
          address: '',
        },
      ],
      invoiceList: [
        {
          bankAccount: '',
          depositBank: '',
          flagDefault: '',
          invoiceTitle: '',
          receivingType: 0,
          regAddress: '',
          regPhone: '',
          taxpayerNumber: '',
        },
      ],
      consigneeOptions: [
        {
          label: '个人',
          value: 1,
        },
        {
          label: '公司',
          value: 2,
        },
      ],
    }
  },
  methods: {
    //设置删除
    setDelete(id, flag) {
      this.axios.get('/api/dealer/dealer/dealerInfo/deleteDealerOther?id=' + id + '&type=' + flag).then((res) => {
        if (res.code == 200) {
          this.$notification.success({ message: res.message })
          selectByIdCustomerInfo(this.rowData.id).then((res) => {
            // 编辑或者查看
            // console.log(res.body)
            // this.rowData = res.body.dealerInfo
            this.contactsList = res.body.contactsList
            this.deliveryAddressesList = res.body.deliveryAddressesList
            this.invoiceList = res.body.invoiceList
          })
        } else {
          this.$notification.success({ message: res.message })
        }
      })
    },
    //设置默认
    setDefault(id, flag) {
      this.axios.get('/api/dealer/dealer/dealerInfo/setDefaultDealerOther?id=' + id + '&type=' + flag).then((res) => {
        if (res.code == 200) {
          this.$notification.success({ message: res.message })
          selectByIdCustomerInfo(this.rowData.id).then((res) => {
            // 编辑或者查看
            // console.log(res.body)
            // this.rowData = res.body.dealerInfo
            this.contactsList = res.body.contactsList
            this.deliveryAddressesList = res.body.deliveryAddressesList
            this.invoiceList = res.body.invoiceList
          })
        } else {
          this.$notification.success({ message: res.message })
        }
      })
    },
    //设置默认收货地址
    setAddressDefault(index) {
      for (let i = 0; i < this.deliveryAddressesList.length; i++) {
        if (index == i) {
          this.deliveryAddressesList[index].flagDefault = true
        } else {
          this.deliveryAddressesList[i].flagDefault = false
        }
      }
    },
    //设置默认发票信息
    setInvoiceDefault(index) {
      for (let i = 0; i < this.invoiceList.length; i++) {
        if (index == i) {
          this.invoiceList[index].flagDefault = true
        } else {
          this.invoiceList[i].flagDefault = false
        }
      }
    },
    //删除发票信息行
    onDelInvoice(item) {
      this.invoiceList.splice(
        this.invoiceList.findIndex((e) => {
          return e == item
        }),
        1
      )
    },
    //添加发票信息行
    addInvoiceRow() {
      this.invoiceList.push({
        bankAccount: '',
        depositBank: '',
        flagDefault: '',
        invoiceTitle: '',
        receivingType: 0,
        regAddress: '',
        regPhone: '',
        taxpayerNumber: '',
      })
    },
    //添加收货地址行
    addAddressRow() {
      this.deliveryAddressesList.push({
        province: '',
        provinceId: 0,
        city: '',
        cityId: 0,
        area: '',
        areaId: 0,
        address: '',
      })
    },
    //删除收货地址行
    onDelAddres(item) {
      this.deliveryAddressesList.splice(
        this.deliveryAddressesList.findIndex((e) => {
          return e == item
        }),
        1
      )
    },
    //设置默认联系人
    setPersonDefault(index) {
      for (let i = 0; i < this.contactsList.length; i++) {
        if (index == i) {
          this.contactsList[index].flagDefault = true
        } else {
          this.contactsList[i].flagDefault = false
        }
      }
    },
    //删除联系人
    onDelPerson(item) {
      this.contactsList.splice(
        this.contactsList.findIndex((e) => {
          return e == item
        }),
        1
      )
    },
    // 联系人修改默认
    personClickFlagDefault(index) {
      if (this.contactsList[index].flagDefault) {
        this.contactsList[index].flagDefault = false
      } else {
        this.contactsList[index].flagDefault = true
      }
    },
    // 添加联系人行
    addPersonRow() {
      this.contactsList.push({ contacts: '', phone: '' })
    },
    callback(key) {
      console.log(key)
    },
    // 获取行数据
    setRowData(row, handle,Classify) {
      this.Classify = Classify
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.RegOff = []
      this.rowData.provinceId = ''
      this.$nextTick(() => {
        this.$refs.pcd.setPCD()
      })
      // 获取大区和事务所数据
      this.axios.get('/api/firm/mhiac/mhiacArea/areaFirmAll').then((res) => {
        this.options = res.body
      })
      // 获取角色模板数据
      this.axios.get('/api/base/dealer/dealerTemplate/listAll').then((res) => {
        this.templateList = res.body
      })
      if (handle == 'add') {
        this.$set(this.rowData, 'flagLinkgap', true)
        this.$set(this.rowData, 'dealerType', 1)
        this.$set(this.rowData, 'cooperateStatus', 1)
      } else if (handle == 'edit' || handle == 'check') {
        selectByIdCustomerInfo(row.id).then((res) => {
          // 编辑或者查看
          console.log(res.body)
          this.rowData = res.body.dealerInfo
          this.contactsList = res.body.contactsList
          this.deliveryAddressesList = res.body.deliveryAddressesList
          this.invoiceList = res.body.invoiceList
          this.RegOff = [res.body.dealerInfo.areaId, res.body.dealerInfo.firmId]
          if (res.body.dealerInfo) {
            this.$set(this.rowData, 'provinceId', res.body.dealerInfo.provinceId)
            this.$set(this.rowData, 'province', res.body.dealerInfo.province)
            this.$set(this.rowData, 'cityId', res.body.dealerInfo.cityId)
            this.$set(this.rowData, 'city', res.body.dealerInfo.city)
            this.$set(this.rowData, 'countyId', res.body.dealerInfo.countyId)
            this.$set(this.rowData, 'county', res.body.dealerInfo.county)
            this.$set(this.rowData, 'address', res.body.dealerInfo.address)
            this.$nextTick(() => {
              this.$refs.pcd.setPCD()
            })
          }
          if (res.body.deliveryAddressesList) {
            for (let i = 1; i < res.body.deliveryAddressesList.length; i++) {
              this.$set(this.deliveryAddressesList[i], 'provinceId', res.body.deliveryAddressesList[i].provinceId)
              this.$set(this.deliveryAddressesList[i], 'province', res.body.deliveryAddressesList[i].province)
              this.$set(this.deliveryAddressesList[i], 'cityId', res.body.deliveryAddressesList[i].cityId)
              this.$set(this.deliveryAddressesList[i], 'city', res.body.deliveryAddressesList[i].city)
              this.$set(this.deliveryAddressesList[i], 'areaId', res.body.deliveryAddressesList[i].areaId)
              this.$set(this.deliveryAddressesList[i], 'area', res.body.deliveryAddressesList[i].area)
              this.$set(this.deliveryAddressesList[i], 'address', res.body.deliveryAddressesList[i].address)
              // this.$nextTick(() => {
              //   this.$refs.addressPCD.setPCD()
              // })
            }
          }
        })
      }
    },
    // 禁用弹框
    handleCancel() {
      this.visible = false
    },
    onChange(val, item) {
      this.rowData.areaId = val ? val[0] : ''
      this.rowData.firmId = val ? val[1] : ''
      this.rowData.area = item ? item[0].name : ''
      this.rowData.firmName = item ? item[1].name : ''
    },
    // 表单提交
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        if (!this.rowData.countyId) {
          return this.$message.warning('请选择门店地址')
        }
        var data = {
          dealerInfo: this.rowData,
          contactsList: this.contactsList,
          deliveryAddressesList: this.deliveryAddressesList,
          invoiceList: this.invoiceList,
        }
        const res = this.handle === 'add' ? await addCustomerInfo(data) : await editCustomerInfo(data)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>
