<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-28 21:53:44
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-09 17:19:01
-->
<template>
  <div>
    <a-range-picker @change="onChange" v-model="dateTimes" v-bind="$attrs" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateTimes: [],
    }
  },
  props: ['startTime', 'endTime'],
  watch: {
    startTime: {
      handler(newVal) {
        if (newVal) {
          this.$set(this.dateTimes, 0, moment(newVal))
        } else {
          this.dateTimes = []
        }
      },
      deep: true,
      immediate: true,
    },
    endTime: {
      handler(newVal) {
        if (newVal) {
          this.$set(this.dateTimes, 1, moment(newVal))
        } else {
          this.dateTimes = []
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onChange(date, dataString) {
      this.$emit('update:startTime', dataString[0])
      this.$emit('update:endTime', dataString[1])
      this.$emit('change')
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
