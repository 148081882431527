<template>
    <div>
      <a-date-picker
          style="width: 100%"
          :show-time="hasTime"
          v-model="startTime2"
          placeholder="请选择时间"
          @change="changeStartTime"
         :get-canlender-container="trigger=>trigger.parentNode"
         :disabled-date="disabledDate"
          v-bind="$attrs">
      </a-date-picker>
    </div>
</template>

<script>
  import moment from 'moment'
   export default {
     data() {
       return {
         startTime2: null,
         format: this.hasTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
       }
     },
     // props:['startTime','isNull'],
     props: {
       startTime: {
         type: String
       },
       isNull: {
         type: Boolean,
         default: false
       },
       hasTime: {
         type: Boolean,
         default: false
       },
       currentTime:{
         type: String,
         default: ''
       }
     },
     watch: {
       startTime:{
         handler(newVal) {
         if (newVal) {
           this.startTime2 = moment(newVal)
         }else{
           this.startTime2 = null
         }
       },
      immediate: true,
       }
     },
     methods:{
      disabledDate(current) {
          // let arr = this.currentTime.split('')
          // console.log("arr",arr)
      return current < moment(this.currentTime).startOf('day');
    },
       changeStartTime() {
         this.$emit("update:startTime", this.startTime2 ? this.startTime2.format(this.format) : '')
         this.$emit("change")
       },
     },
     created() {
       this.$emit("update:startTime", this.startTime2 ? this.startTime2.format(this.format) : '')
     }
   }
</script>

<style lang="less" scoped>
</style>
