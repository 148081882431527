import api from './index'
import { axios, pureAxios } from '@/utils/request'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return axios({
    // url: '/base/auth/login',
    url: api.Login,
    method: 'post',
    data: parameter
  })
}
export function login1 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url: '/api/base/dealer/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login2 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url: '/api/base/firm/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login3 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://szcloud.5uplus.com/api/base/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login4 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://szdealer.5uplus.com/api/base/dealer/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login5 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://szfirm.5uplus.com/api/base/firm/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login6 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://njcloud.5uplus.com/api/base/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login7 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://njdealer.5uplus.com/api/base/dealer/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login8 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://njfirm.5uplus.com/api/base/firm/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login9 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://bjcloud.5uplus.com/api/base/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login10 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://bjdealer.5uplus.com/api/base/dealer/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login11 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://bjfirm.5uplus.com/api/base/firm/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login12 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://hzcloud.5uplus.com/api/base/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login13 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://hzdealer.5uplus.com/api/base/dealer/auth/login',
    method: 'post',
    data: parameter
  })
}
export function login14 (parameter) {
  return axios({
    // url: '/base/auth/login',
    url:'https://hzfirm.5uplus.com/api/base/firm/auth/login',
    method: 'post',
    data: parameter
  })
}

export function getSmsCaptcha (parameter) {
  return axios({
    url: api.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo () {
  return axios({
    url: api.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

export function logout (token) {
  return axios({
    url: api.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'token': token
    }
  })
}

export function imgcode () {
  return pureAxios({
    url: '/code',
    method: 'get',
    responseType: 'blob'
  })
}

/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step (parameter) {
  return axios({
    url: api.twoStepCode,
    method: 'post',
    data: parameter
  })
}

// 
export const getConfirmModal = params => axios({
  url: '/api/base/system/sysUpgradeRecord/edit',
  method: 'post',
  data: JSON.stringify(params),
  headers: {
      'Content-Type': 'application/json;charset=UTF-8'
  }
})