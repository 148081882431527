<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-05 14:10:28
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-14 12:05:43
-->
<template>
  <div class="">
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="订单编号">
            <a-input v-model="searchData.orderCode" allowClear placeholder="订单编号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="销售合同号">
            <a-input v-model="searchData.linkGapContractNum" allowClear placeholder="销售合同号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="采购合同号">
            <a-input v-model="searchData.stContractNum" allowClear placeholder="采购合同号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="大区事务所">
            <a-cascader
              :options="dealerList"
              v-model="regionalOffice"
              change-on-select
              placeholder="请选择大区/事务所"
              @change="onChange"
              :field-names="{ label: 'title', value: 'id', children: 'children' }"
            />
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="经销商">
            <a-input v-model="searchData.dealerName" allowClear placeholder="经销商名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="业绩归属">
            <a-input v-model="searchData.belongDealerName" allowClear placeholder="业绩归属经销商名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12" v-if="showOrderStatus == 'order_info'">
          <a-form-model-item label="订单状态">
            <DictSelect
              field="orderStatus"
              :value.sync="searchData.orderStatus"
              style="width: 100%"
              placeholder="请选择订单状态"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12" v-if="showOrderStatus == 'audit_finish_order'">
          <a-form-model-item label="订单状态">
            <DictSelect
              field="auditedStatus"
              :value.sync="searchData.orderStatus"
              style="width: 100%"
              placeholder="请选择订单状态"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12" v-if="showOrderStatus == 'delivery_order'">
          <a-form-model-item label="订单状态">
            <DictSelect
              field="shippenStatus"
              :value.sync="searchData.orderStatus"
              style="width: 100%"
              placeholder="请选择订单状态"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
<!--        <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--          <a-form-model-item label="合同类型">-->
<!--            <a-select placeholder="请选择合同类型" v-model="searchData.contractType">-->
<!--              <a-select-option :value="item.id" v-for="(item, index) in contractTypeList" :key="index">-->
<!--                {{ item.title }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="订单类型">
            <a-select placeholder="请选择订单类型" allowClear v-model="searchData.orderTypeId">
              <a-select-option :value="item.id" v-for="(item, index) in orderTypeList" :key="index">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <!-- <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="供应商" :autoLink="false" ref="supplier">
            <a-select placeholder="请选择供应商" v-model="searchData.supplierId" @change="onSupplierChange">
              <a-select-option :value="item.id" v-for="(item, index) in supplierList" :key="index">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col> -->
        <!-- <a-col :md="8" :xl="6" :xxl="6" :sm="12" v-if="changeRadio == 1">
          <a-form-model-item label="仓库">
            <a-select placeholder="请选择仓库" v-model="searchData.warehouseId">
              <a-select-option :value="item.id" v-for="(item, index) in warehouseList" :key="index">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col> -->
        <!--        <a-col :md="8" :xl="6" :xxl="6" :sm="12" v-if="changeRadio == 1">-->
        <!--          <a-form-model-item label="仓库">-->
        <!--            <a-select placeholder="请选择仓库" v-model="searchData.warehouseId">-->
        <!--              <a-select-option :value="item.id" v-for="(item, index) in warehouseList" :key="index">{{item.title}}</a-select-option>-->
        <!--            </a-select>-->
        <!--          </a-form-model-item>-->
        <!--        </a-col>-->
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="下单方式">
            <DictSelect
              field="sourceType"
              :value.sync="searchData.sourceType"
              style="width: 100%"
              placeholder="请选择下单方式"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
<!--        <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--          <a-form-model-item label="是否包邮">-->
<!--            <DictSelect-->
<!--              field="flagFreeShipping"-->
<!--              :value.sync="searchData.flagFreeShipping"-->
<!--              style="width: 100%"-->
<!--              placeholder="请选择是否包邮"-->
<!--            ></DictSelect>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->

        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="发货性质">
            <DictSelect
              field="deliveryProperties"
              :value.sync="searchData.deliveryProperties"
              style="width: 100%"
              placeholder="请选择发货性质"
            ></DictSelect>
          </a-form-model-item>
        </a-col>

<!--        <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--          <a-form-model-item label="购销合同">-->
<!--            <DictSelect-->
<!--              field="purchaseAndSalesContractType"-->
<!--              :value.sync="searchData.purchaseAndSalesContractType"-->
<!--              style="width: 100%"-->
<!--              placeholder="请选择购销合同"-->
<!--            ></DictSelect>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
<!--        <a-col :xxl="6" :xl="6" :md="8" :sm="12">-->
<!--          <a-form-model-item label="到货日期">-->
<!--            <DateRange :startTime.sync="searchData.startTimeForExp" :endTime.sync="searchData.endTimeForExp"></DateRange>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
        <a-col :xxl="6" :xl="6" :md="8" :sm="12">
          <a-form-model-item label="下单时间">
            <!-- <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange> -->
            <a-range-picker v-model="chartTime"  :ranges="ranges"></a-range-picker>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="6" :md="8" :sm="12">
          <a-form-model-item label="购货单位">
            <DictSelect
              field="receivingUnit"
              :value.sync="searchData.consigneeType"
              style="width: 100%"
              placeholder="请选择购货单位"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12" v-if="showOrderStatus == 'audit_finish_order' || showOrderStatus == 'to_delivery_order'">
          <a-form-model-item label="合同号">
            <a-input v-model="searchData.contractNum" allowClear placeholder="销售/采购合同号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import bus from './eventBus.js'
import moment from 'moment'
export default {
  name: 'orderSearch',
  data() {
    return {
      searchData: {},
      contractTypeList: [],
      orderTypeList: [],
      dealerList: [],
      regionalOffice: [],
      supplierList: [],
      warehouseList: [],
      changeRadio: 2,
      chartTime: [moment().startOf('month'), moment()],
      ranges:{
          '近一周': [moment().subtract(1, 'weeks'), moment()],
          '近一月': [moment().subtract(1, 'months'), moment()],
          '近一年': [moment().subtract(1, 'years'), moment()],
          '本周': [moment().startOf('week'), moment()],
          '本月': [moment().startOf('month'), moment()],
          '本年': [moment().startOf('year'), moment()]
        },
    }
  },

  props: ['showOrderStatus'],

  computed: {},


  created() {
    // this.axios.get('/api/base/system/dictionary/selectByCode/' + '合同类型').then((res) => {
    //   // 获取字典合同类型
    //   this.contractTypeList = res.body
    // })
    this.axios.get('/api/base/system/dictionary/selectByCode/' + '订单类型').then((res) => {
      // 获取字典订单类型
      this.orderTypeList = res.body
    })
    this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
      // 获取大区事务所经销商数据
      this.dealerList = res.body
    })
    this.axios.get('/api/base/system/dictionary/selectByCode/' + '供应商').then((res) => {
      // 获取字典供应商
      this.supplierList = res.body
    })
    this.axios.get('/api/base/system/dictionary/selectByCode/' + '仓库').then((res) => {
      // 获取字典仓库
      this.warehouseList = res.body
    })
  },
  mounted(){
    this.getDataFilter()


  },
  watch: {
       chartTime(newVal) {
         this.chartTime=newVal?newVal:[]
       }
     },
  methods: {
    // 查询
    getDataFilter(num) {
      let obj = {
        ...this.searchData
      }
      if(this.chartTime.length > 0){
        obj.startTime=this.chartTime[0].format("YYYY-MM-DD"),
        obj.endTime=this.chartTime[1].format("YYYY-MM-DD")
      }
      if(!num){
          this.$bus.$emit('getList', obj)
      }else{
        this.$bus.$emit('getchangeTable', obj)
      }

      this.$emit('getSearchData', obj)
    },

    // 重置
    reset() {
      this.searchData = {}
      this.chartTime=[moment().startOf('month'), moment()]
      this.regionalOffice = []
      let obj = {
        startTime:this.chartTime[0].format("YYYY-MM-DD"),
        endTime:this.chartTime[1].format("YYYY-MM-DD")
      }
      this.$bus.$emit('reset', obj)
      this.$emit('getSearchData', obj)
    },

    onChange(val) {
      this.searchData.bigAreaId = val[0]
      this.searchData.firmId = val[1]
    },

    onSupplierChange(val) {
      this.$refs.supplier.onFieldChange()
      if (val == 11) {
        this.changeRadio = 1
      } else {
        this.changeRadio = 2
      }
    },
  },
}
</script>

<style lang='scss' scoped>
</style>