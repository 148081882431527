
//部署上海 使用以下代码
export const port = process.env.VUE_APP_PORT

export const copyright = process.env.VUE_APP_COPYRIGHT


let typeOptions;

if(port=='shanghai'){
  typeOptions = [
    {
      label: '农业银行（尾号4875）',
      value: 1,
    },
    {
      label: '招商银行（尾号0601）',
      value: 2,
    },
  ]
}else if(port == 'hangzhou'){
  typeOptions = [
    {
      label: '工商银行（尾号5669）',
      value: 1,
    }
  ]
}else if(port == 'nanjing'){
  typeOptions = [
    {
      label: '建设银行（尾号0983）',
      value: 1,
    },
    {
      label: '邮政银行（尾号8898）',
      value: 1,
    }
  ]
}else if(port == 'beijing'){
  typeOptions = [
    {
      label: '建设银行（尾号1259）',
      value: 1,
    }
  ]

}else if(port == 'suzhou'){
  typeOptions = [
    {
      label: '建设银行（尾号1405）',
      value: 3,
    },
    {
      label: '中信银行（尾号0292）',
      value: 4,
    },
  ]
}

export {
  typeOptions
}

