<!--
 * @Description: AKJERRT
 * @Date: 2022-07-07 17:08:00
 * @LastEditTime: 2022-09-23 09:18:43
 * @FilePath: \linkgap-front\src\App.vue
-->
<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view v-if="isRouterAlive" />
      <initCongirmModal />
    </div>
  </a-config-provider>
</template>

<script>
// 初始化弹窗
import initModal from './components/initModal/initModal.vue'
import initCongirmModal from './components/initCongirmModal/initCongirmModal.vue'
// 判断当前设备
import { AppDeviceEnquire } from '@/utils/mixin'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
// 引用cdn还可以这么写  射中ant-design-vue默认为中文
// import {
//   locales
// } from 'ant-design-vue'
// const zhCN = locales.zh_CN

export default {
  mixins: [AppDeviceEnquire],
  components: {
    initModal,
    initCongirmModal,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      locale: zhCN,
      isRouterAlive: true,

    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    getPopupContainer(el) {
      if (el) {
        return el.parentNode
      } else {
        return document.body
      }
    },
  },
  mounted() {},
}
</script>
