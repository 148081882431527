import Vue from 'vue'
import { login,login1,login2,login3,login4,login5,login6,login7,login8,login9,login10,login11,login12,login13,login14,getInfo, logout } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'

const user = {
  state: {
    userId: '',
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    buttons: [], // 按钮权限
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_BUTTONS: (state, buttons) => {
      state.buttons = buttons
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.code === 200) {
            const result = response.body
            Vue.ls.set(ACCESS_TOKEN, result.token, 12 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.token)
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
     //  上海经销商登录
     Login1 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login1(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 上海事务所登录
    Login2 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login2(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 苏州管理端登录
    Login3 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login3(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 苏州经销商登录
    Login4 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login4(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 苏州事务所登录
    Login5 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login5(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
     // 南京管理端登录
     Login6 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login6(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 南京经销商登录
    Login7 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login7(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 南京事务所登录
    Login8 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login8(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
      // 北京管理端登录
      Login9 ({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
          login9(userInfo).then(response => {
              resolve(response)
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 北京经销商登录
      Login10 ({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
          login10(userInfo).then(response => {
              resolve(response)
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 北京事务所登录
      Login11 ({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
          login11(userInfo).then(response => {
              resolve(response)
          }).catch(error => {
            reject(error)
          })
        })
      },
        // 杭州管理端登录
     Login12 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login6(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 杭州经销商登录
    Login13 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login13(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 杭州事务所登录
    Login14 ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login14(userInfo).then(response => {
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response.body
          if (result.roleIds) {
            commit('SET_ROLES', result.roleIds)
            commit('SET_BUTTONS', result.buttons)
            commit('SET_USERID', result.id)
            commit('SET_INFO', result)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }
          // console.log(result)
          commit('SET_NAME', { name: result.login.nickName, welcome: welcome() })

          let head = 'https://oss.vclus.com/boy.png';
          if(result.sex == 2){
            head = 'https://oss.vclus.com/girl.png'
          }
          commit('SET_AVATAR', result.login.avatar || head)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_INFO', {})
          Vue.ls.remove(ACCESS_TOKEN)
        })
      })
    }

  }
}

export default user
