/*
 * @Description: AKJERRT
 * @Date: 2022-07-26 11:14:15
 * @LastEditTime: 2022-09-05 15:27:28
 * @FilePath: \linkgap-front\src\store\modules\common.js
 */
import { getUpgradeRemind } from "../../components/initCongirmModal/api/api"
const state = {
  loginModal: false,
  routerLink: false,
  confirmModal: false,
  confirmModalData: {},
  keepAlive: false,
  keepAliveData: {}
}
const getters = {
}
const mutations = {
  SET_LOFIN_MODAL(state) {
    state.loginModal = !state.loginModal
  },
  SET_LOFIN_MODAL_ClOSE(state) {
    state.loginModal = false
  },
  SET_CONFIRM_MODAL(state, data) {
    state.confirmModal = true
    state.confirmModalData = data.content
  },
  SET_CONFIRM_MODAL_ClOSE(state) {
    state.confirmModal = false
  },
  SET_KEEP_ALIVE_STATUS(state,data) {
    state.keepAlive = true
    state.keepAliveData = data
  },
  CRELTE_KEEP_ALIVE_STATUS(state) {
    state.keepAlive = false
  }
}
const actions = {
  getInitBill({ commit }, data) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (true) {
          commit('SET_LOFIN_MODAL')
        } else {
        }
      }, 2000)
    })
  },
  getInitConfirm({ commit }, data) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        getUpgradeRemind().then(({ code, body, message }) => {
          if (code === 200 && body.flag) {
            commit('SET_CONFIRM_MODAL', body)
          }
        })
      }, 3000)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
